import {
  Onboarding,
  OnboardingType,
  OnboardingUserInfo,
} from './model';
import { APP_BASE_URL } from 'src/constants';
import { Configuration, Keyring, UserType } from "../users/model";
import { makeRequest } from '../common/client';
import { SpaceType } from 'src/contexts/AuthContext/Space';
import { MetaType } from '../meta/MetaConfiguration';

import { MetaAlbums, defaultAlbums, addAlbum } from "src/api/wishbook/safes/common/MetaAlbums";
import { MetaDocuments, defaultDocuments, addFolder } from "src/api/wishbook/safes/common/MetaDocuments";

import * as metaApi from "src/api/wishbook/meta/api";
import { PersistentContext } from 'src/contexts/AuthContext/PersistentContext';
import { SubscriptionPaymentProvider } from '../subscriptions/model';
import { v4 as uuidv4 } from 'uuid';

export const getAll = async (): Promise<Onboarding[]> => {
  const response = await makeRequest('GET', '/api/v1/users/me/onboardings');
  return response.data.onboardings;
};

export const getAndUpdate = async (onboardingId: string): Promise<Onboarding> => {
  const response = await makeRequest('GET', `/api/v1/users/me/onboardings/${onboardingId}`);
  return response.data;
};

export const create = async (onboardingType: OnboardingType, onboardingUserInfo?: OnboardingUserInfo, onboardingSubscription?: SubscriptionInput): Promise<Onboarding> => {
  const payload: any = {
    "step_type": onboardingType
  };
  if (onboardingUserInfo) payload.step_userinfo = onboardingUserInfo;
  if (onboardingSubscription) {
    if (onboardingSubscription.callback_url == null) onboardingSubscription.callback_url = `${APP_BASE_URL}/onboarding`;
    payload.step_subscription = onboardingSubscription;
  }
  const response = await makeRequest('POST', '/api/v1/users/me/onboardings', payload);
  return response.data;
};

// export const get = async (obId: string): Promise<Onboarding> => {
//   const response = await makeRequest('GET', `/api/v1/users/me/onboardings/${obId}`);
//   return response.data;
// };

interface SubscriptionInput {
  sku?: string,
  coupon?: string,
  provider?: SubscriptionPaymentProvider;
  payment_method_id?: string;
  price_id?: string;
  callback_url?: string;
};
export const updateSubscription = async (obId: string, subscriptionInput: SubscriptionInput): Promise<Onboarding> => {
  subscriptionInput.callback_url = `${APP_BASE_URL}/onboarding`;
  const response = await makeRequest('POST', `/api/v1/users/me/onboardings/${obId}/update-subscription`, subscriptionInput);
  return response.data;
};

export const updateUserInfo = async (obId: string, userInfo: OnboardingUserInfo): Promise<Onboarding> => {
  const response = await makeRequest('POST', `/api/v1/users/me/onboardings/${obId}/update-userinfo`, userInfo);
  return response.data;
};

interface OnboardingFinalStepClassic {
  step_key?: Keyring,
  step_userinfo?: OnboardingUserInfo,
}
interface OnboardingFinalStepKids {
  step_configuration?: Configuration,
  step_userinfo?: OnboardingUserInfo,
}

export const finalize = async (obId: string, finalStep: OnboardingFinalStepClassic|OnboardingFinalStepKids): Promise<Onboarding> => {
  const response = await makeRequest('POST', `/api/v1/users/me/onboardings/${obId}/finalize`, finalStep);
  return response.data;
};

export const postFinalize = async (context: PersistentContext, onboarding: Onboarding) => {
  if (onboarding.step_type.type === UserType.MEMBER) { return; }

  if (onboarding.step_type.space === SpaceType.CLASSIC && onboarding.step_type.type === UserType.WISHBOOKER) {
    const albumsCollections = __createAlbums(context);
    const professionalCollections = __createProfessionalDocuments(context);
    const documentsCollections = __createDocuments(context);
    await Promise.all([albumsCollections, documentsCollections, professionalCollections]);
  }
};

const __createAlbums = async (context: PersistentContext): Promise<void> => {
  const memories: MetaAlbums = defaultAlbums(MetaType.MetaTypeClassicAlbums);
  addAlbum(memories, {
    title: "Enfance",
    description: "Dans cette partie de votre vie il se peut que vous ayez des souvenirs extraordinaires, des lieux qui ont compté, une maison remplie de souvenirs, une photo unique avec un proche que vous avez perdu ensuite, un rêve d’enfant que vous avez concrétisé adulte… A vous de raconter votre histoire !"
  });
  addAlbum(memories, {
    title: "Adolescence / jeune adulte",
    description: "A ce stade, l’adolescence et la vie de jeune adulte peuvent paraître anecdotiques mais vous y avez peut-être fait des rencontres marquantes, eu un look ravageur, passé vos études, commencé à travailler, découvert une passion, eu votre premier appartement, vos premiers coups de coeur… Partagez leur qui vous êtes."
  });
  addAlbum(memories, {
    title: "Adulte",
    description: "Adulte est un grand mot car il est si subjectif ! Dans cette phase de vie il y a sûrement de grandes histoires à raconter sur votre vie : une belle rencontre, une union, des voyages, des challenges de vie, des fêtes avec ceux qu’on aime, l’arrivée des enfants, la famille qui s’agrandit, les amitiés qui traversent les années…"
  });
  addAlbum(memories, {
    title: "Plus de 60 ans",
    description: "A cette étape, la vie est bien remplie et certains disent que “vieillir est un honneur.” Peut-être que vous avez un recul sur la vie qui vous a apporté des bonheurs différents. Pensez aussi à partager vos voyages, la rencontre avec vos petits-enfants, des grandes réunions de famille...Partagez-les avec ceux que vous aimez car votre vécu restera grâce à ces coffres-forts."
  });
  await metaApi.upsert(context, memories);
};

const __createDocuments = async (context: PersistentContext): Promise<any> => {
  const documents: MetaDocuments = defaultDocuments(MetaType.MetaTypeClassicDocuments);

  addFolder(documents, {
    title: "Mon identité",
    description: "",
    tags: [
      "id",
      "livret de famille",
      "arbre généalogique",
      "test ADN"
    ]
  });
  addFolder(documents, {
    title: "Parcours & Réalisations",
    description: "",
    tags: [
      "diplômes",
      "brevets industriel",
      "création",
      "kbis",
      "parution sur vos réalisations",
      "revue de presse",
    ]
  });
  addFolder(documents, {
    title: "Mon patrimoine",
    description: "",
    tags: [
      "actes de propriété",
      "actes notariés",
      "objects détenus avec une histoire à transmettre",
    ]
  });
  addFolder(documents, {
    title: "Santé",
    description: "",
    tags: [
      "important",
    ]
  });
  addFolder(documents, {
    title: "Secrets",
    description: "",
    tags: [
      "j'ai caché",
      "ce que je n'ai pas dit",
    ]
  });
  await metaApi.upsert(context, documents);
};

export const __createProfessionalDocuments = async (context: PersistentContext): Promise<any> => {
  const documents: MetaDocuments = defaultDocuments(MetaType.MetaTypeClassicProfessionalDocuments);

  addFolder(documents, {
    job: "wealth_manager",
    title: "Gestionnaire de patrimoine",
    uuid: uuidv4(),
    description: "",
    tags: [
      "documents de gestion",
      "documents de suivi",
      "documents de transmission",
    ],
    subfolders: [
        {
            title: "État des placements et investissements",
            uuid: uuidv4(),
            description: "",
            tags: []
        },
        {
            title: "Plan de retraite",
            uuid: uuidv4(),
            description: "",
            tags: []
        },
        {
            title: "Relevés de comptes",
            uuid: uuidv4(),
            description: "",
            tags: []
        },
        {
            title: "Stratégies d'investissement",
            uuid: uuidv4(),
            description: "",
            tags: []
        },
        {
            title: "Documents fiscaux",
            uuid: uuidv4(),
            description: "",
            tags: []
        },
        {
            title: "Assurances",
            uuid: uuidv4(),
            description: "",
            tags: []
        },
    ]
  }, true);
    addFolder(documents, {
        job: "notary",
        title: "Notaire",
        uuid: uuidv4(),
        description: "",
        tags: [
        "actes notariés",
        "succession",
        "testament",
        "donation",
        ],
        subfolders: [
            {
                title: "Testament",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Donations",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Actes de propriété",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Procurations",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Contrat de mariage",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Inventaire des biens",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
        ]
    }, true);
    addFolder(documents, {
        job: "lawyer",
        title: "Avocat",
        uuid: uuidv4(),
        description: "",
        tags: [
        "contrats",
        "conseils",
        "déclarations",
        ],
        subfolders: [
            {
                title: "Contrats",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Procédures personnelles",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Procédures professionnelles",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Conseils juridiques",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Accord de confidentialité",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "En cours",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
        ]
    }, true);

    addFolder(documents, {
        job: "insurer",
        title: "Assureur",
        uuid: uuidv4(),
        description: "",
        tags: [
        "contrats",
        "attestations",
        "déclarations",
        ],
        subfolders: [
            {
                title: "Contrats d' assurance (vie, habitation, auto, santé)",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Relevés d'indemnisation",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Conditions générales et particulières",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Correspondances avec l’ assureur",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
        ]
    }, true);

    addFolder(documents, {
        job: "real_estate_agent",
        title: "Agent immobilier",
        uuid: uuidv4(),
        description: "",
        tags: [
        "contrats",
        "attestations",
        "déclarations",
        ],
        subfolders: [
            {
                title: "Actes de propriété",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Diagnostics immobiliers",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Plans et schémas techniques",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Contrats et documents juridiques",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Documents fiscaux",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Justificatifs et certificats",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
        ]
    }, true);

    addFolder(documents, {
        job: "accountant",
        title: "Expert comptable",
        uuid: uuidv4(),
        description: "",
        tags: [
        "contrats",
        "attestations",
        "déclarations",
        ],
        subfolders: [
            {
                title: "Bilans annuels",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Relevés fiscaux",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Feuilles de paie",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Déclarations fiscales",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Comptes de résultat",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
        ]
    }, true);

    addFolder(documents, {
        job: "banker",
        title: "Banquier",
        uuid: uuidv4(),
        description: "",
        tags: [
        "comptes",
        "contrats",
        "déclarations",
        ],
        subfolders: [
            {
                title: "Relevés de compte",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Informations sur les emprunts",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Plan de financement",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Comptes d'épargne",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Cartes de crédit",
                uuid: uuidv4(),
                description: "",
                tags: []
            },
            {
                title: "Compte Pro",
                uuid: uuidv4(),
                description: "",
                tags: []
            }
        ]
    }, true);

    addFolder(documents, {
        job: "family_office",
        title: "Family office",
        uuid: uuidv4(),
        description: "",
        tags: [
        "contrats",
        "attestations",
        "déclarations",
        ],
    }, true);

  await metaApi.upsert(context, documents, true);
  }

