import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import { useIntercom } from 'react-use-intercom';

import SplashScreen from 'src/components/SplashScreen';

import { UserCreation } from 'src/api/uma/users/model';
import * as usersUma from 'src/api/uma/users/api';
import * as authUma from 'src/api/uma/auth/api';

import {
  decodeTokens,
  isValid,
  hasEmailConfirmed,
} from 'src/api/uma/auth/model';

import * as uma from 'src/api/uma/common/client';
import * as client from 'src/api/wishbook/common/client';
import * as downloader from 'src/api/tus/downloader';
import * as uploader from 'src/api/tus/uploader';

import { PersistentContext } from 'src/contexts/AuthContext/PersistentContext';
import { SubscriptionStatus } from 'src/api/wishbook/subscriptions/model';
import { SubscriptionSkus } from 'src/api/wishbook/subscriptions/helper';
import * as userApi from "../../api/wishbook/users/api";

const ACTION_DEFAULT = "INITIALISED";
const ACTION_INITIALISED = "INITIALISED";
const ACTION_NEED_EMAIL_VALIDATION = "NEED_EMAIL_VALIDATION";
const ACTION_NEED_ONBOARDING = "NEED_ONBOARDING";
const ACTION_LOGGED_OUT = "LOGGED_OUT";
const ACTION_READY = "READY";

interface Action {
  type: string;
  context: PersistentContext;
}

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ACTION_NEED_ONBOARDING: {
      return {
        ...state,
        context: action.context,
        isAuthenticated: true,
        isOnboarded: false,
        hasValidatedEmail: true,
      };
    }
    case ACTION_NEED_EMAIL_VALIDATION: {
      return {
        ...state,
        context: action.context,
        isAuthenticated: true,
        isOnboarded: false,
        hasValidatedEmail: false,
      };
    }
    case ACTION_LOGGED_OUT: {
      return {
        ...state,
        context: action.context,
        isAuthenticated: false,
        isOnboarded: false,
        hasValidatedEmail: false,
      };
    }
    case ACTION_READY: {
      return {
        ...state,
        context: action.context,
        isAuthenticated: true,
        isOnboarded: true,
        hasValidatedEmail: true,
      };
    }
    case ACTION_INITIALISED: {
      return {
        ...state,
        context: action.context,
        __isInitialised: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface AuthState {
  __isInitialised: boolean;
  isAuthenticated: boolean;
  isOnboarded: boolean;
  hasValidatedEmail: boolean;
  context: PersistentContext;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  simpleLogin: (email: string, password: string) => Promise<void>;
  logout: () => { };
  // register: (userCreation: UserCreation) => Promise<void>;
  makeReady: () => void;
  makeNeedOnboarding: () => void;
}

const initialAuthState: AuthState = {
  __isInitialised: false,
  isOnboarded: false,
  hasValidatedEmail: false,
  isAuthenticated: false,
  context: PersistentContext.load(),
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  simpleLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  // register: () => Promise.resolve(),
  makeReady: () => Promise.resolve(),
  makeNeedOnboarding: () => Promise.resolve(),
});

async function removeCache() {
  console.log("Trying to remove cache");
  const cacheKeys = await window.caches.keys();
  if (cacheKeys && cacheKeys.length > 0) {
    console.log("Cache keys found");
    console.log(cacheKeys);
    await Promise.all(cacheKeys.map(key => {
      window.caches.delete(key);
    }));

    const newCacheKeys = await window.caches.keys();
    console.log("Cache keys verification");
    console.log(newCacheKeys);

    window.location.replace(window.location.href);
  } else {
    console.log("No cache keys");
  }
};

interface AuthProviderProps { children: ReactNode };
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const intercom = useIntercom();

  const common = async () => {
    const decodedAuthModel = decodeTokens(state.context.authentication);
    intercom.shutdown();
    intercom.boot({
      email: decodedAuthModel.decodedAccessToken["https://emails.wishbook/"],
      userId: decodedAuthModel.decodedAccessToken["sub"],
    });

    uma.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);
    client.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);
    downloader.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);
    uploader.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);

    state.context.save();

    // if (hasEmailConfirmed(decodedAuthModel) == false) {
    //   dispatch({ type: ACTION_NEED_EMAIL_VALIDATION, context: state.context });
    //   return;
    // }

    try {
      await state.context.refreshMySpace();
      updateIntercom();
    } catch(error) {

      // if (state.context.space && state.context.space.user == null) {
      //   if (state.context.space.onboardings == null || state.context.space.onboardings.length == 0) {
      //     dispatch({ type: ACTION_NEED_ONBOARDING, context: state.context });
      //   }
      // }

      // console.log(error);
      // if (error.response && error.response.status === 404) {
      //   dispatch({ type: ACTION_NEED_ONBOARDING, context: state.context });
      // }

      console.log(error);
      dispatch({type: ACTION_NEED_ONBOARDING, context: state.context});
      return ;
    }
    dispatch({ type: ACTION_READY, context: state.context });
  };

  const updateIntercom = () => {
    console.log('UPDATE INTERCOM');
    intercom.update({
      name: `${state.context.mySpace.user.first_name} ${state.context.mySpace.user.last_name}`,
      phone: state.context.mySpace.user.phone,
      customAttributes: {
        skus: state.context.mySpace.onboardings ? state.context.mySpace.onboardings.filter((onboarding) => onboarding.step_subscription != null && onboarding.step_subscription.status == SubscriptionStatus.ACTIVATED).map((onboarding) => onboarding.step_subscription.sku).join(',') : "",
        dob: state.context.mySpace.user.dob,
      }
    });

    // todo: can be removed whenever all existing freemium users (before 19Jan.) have logged in again
    if (state.context.mySpace.onboardings) {
      const freemium = state.context.mySpace.onboardings.find((onboarding) => onboarding.step_subscription != null && onboarding.step_subscription.sku == SubscriptionSkus.comWishbookClassicFreemium);
      if (freemium != null) {
        intercom.update({
          customAttributes: {
            "patrimony-types": freemium.step_userinfo.metadata.patrimonyTypes.join(','),
            "patrimony-amount": freemium.step_userinfo.metadata.patrimonyAmount,

            "children": freemium.step_userinfo.metadata.situation.numberOfChildren,
            "status": freemium.step_userinfo.metadata.situation.situationType,
            "notary-consultation": freemium.step_userinfo.metadata.situation.notaryConsultation,
            "redacted-testament": freemium.step_userinfo.metadata.situation.redactedTestament,

            "wishbook-interests": freemium.step_userinfo.metadata.survey.join(','),
          }
        });
      }
    }
  };

  const makeReady = () => {
    if (state.context.mySpace.user) {
      updateIntercom();
    }

    dispatch({ type: ACTION_READY, context: state.context });
  };

  /*
  ** Refresh
  */
  const refresh = async () => {
    console.log("[AuthContext] Refreshing token");
    state.context.authentication = await authUma.refresh(state.context.authentication);
    console.log(`[AuthContext] Refreshed ${state.context.authentication.access_token}`);
    await common();
  };

  /*
  ** Login
  */
  const login = async (email: string, password: string) => {
    state.context.authentication = await authUma.login(email, password);
    await common();
  };

  const simpleLogin = async (email: string, password: string) => {
    state.context.authentication = await authUma.login(email, password);

    const decodedAuthModel = decodeTokens(state.context.authentication);
    intercom.shutdown();
    intercom.boot({
      // todo: check if this key is still there ?
      email: decodedAuthModel.decodedAccessToken["https://emails.wishbook/"],
      userId: decodedAuthModel.decodedAccessToken["sub"],
    });

    uma.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);
    client.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);
    downloader.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);
    uploader.assignDefaultAuthorizationBearerToken(state.context.authentication.access_token);

    state.context.save();
  };

  const makeNeedOnboarding = () => {
    dispatch({ type: ACTION_NEED_ONBOARDING, context: state.context });
  }

  /*
  ** Logout
  */
  const logout = async () => {
    await authUma.logout(state.context.authentication);
    cleanSession();
    dispatch({ type: ACTION_LOGGED_OUT, context: state.context });
  };

  const cleanSession = () => {
    state.context.reset();
    state.context.remove();
    intercom.shutdown();
    uma.assignDefaultAuthorizationBearerToken(null);
    client.assignDefaultAuthorizationBearerToken(null);
    downloader.assignDefaultAuthorizationBearerToken(null);
    uploader.assignDefaultAuthorizationBearerToken(null);
  };

  /*
  ** Register
  */
  // const register = async (userCreation: UserCreation) => {
  //   await usersUma.create(userCreation);
  //   dispatch({ type: ACTION_DEFAULT, context: state.context });
  // };

  useEffect(() => {
    const initialise = async () => {
      console.log("[AuthContext] Initializing...");
      // await removeCache();

      if (state.context.authentication) {
        console.log(state.context.authentication);

        if (isValid(state.context.authentication)) {
          console.log("[AuthContext] Token is valid");
          await common();
        } else {
          console.log("[AuthContext] Token is invalid");
          try {
            await refresh();
          } catch {
            cleanSession();
            dispatch({ type: ACTION_INITIALISED, context: state.context });
          }
        }
      }
      dispatch({ type: ACTION_INITIALISED, context: state.context });
    };

    initialise();
  }, []);

  if (state.__isInitialised == false) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        simpleLogin,
        logout,
        // register,
        makeReady,
        makeNeedOnboarding,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
