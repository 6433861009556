import React, {
  useRef,
  useState
} from 'react';
import type { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../theme';
import i18n from '../../../i18n';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  color: {
    color: theme.palette.secondary.main
  },
  guardianColor: {
    color: theme.palette.guardian.main
  },
  changeSpaceLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const Account: FC = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { context, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      // history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Hidden smDown>
          <Box pr={2} flexDirection="column" textAlign="right">
            <Typography
              variant="h6"
              className={  classes.color }
            >
              {`${context.mySpace.user.first_name} ${context.mySpace.user.last_name}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {`${context.authentication.decodedIdToken.email}`}
            </Typography>
          </Box>
        </Hidden>
        <img
          src="/static/images/navIcons/account-icon.svg"
        />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem> */}
        {/* <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          {t('topbar_account_menu_account')}
        </MenuItem> */}
        <Link className={classes.changeSpaceLink} to={`/${i18n.language}/space-select`}>
          <MenuItem>
            {t('navbar_switch_space')}
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>
        {t('topbar_account_menu_logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
