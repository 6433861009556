import moment from "moment";
import _ from "lodash";

import { defaultSubscriptionConfiguration, getSubscriptionConfigurationForSku, SubscriptionSkus } from "src/api/wishbook/subscriptions/helper";
import { SubscriptionPayment, SubscriptionStatus } from "src/api/wishbook/subscriptions/model";
import { SpaceType } from "src/contexts/AuthContext/Space";
import { PersistentContext } from '../../../contexts/AuthContext/PersistentContext';
import * as userApi from "./api";

export enum SpaceStatus {
  OPENED = "OPENED",
  CLOSED = "CLOSED",
  UNDEFINED = "UNDEFINED",
}

export enum UserType {
  WISHBOOKER = "WISHBOOKER",
  MEMBER = "MEMBER",
};

export enum MemberType {
  PROFESSIONAL = "professional",
  RELATIVE = "relative",
};

export enum JobType {
  WEALTH_MANAGER = "wealth_manager",
  NOTARY = "notary",
  LAWYER = "lawyer",
  INSURER = "insurer",
  REAL_ESTATE_AGENT = "real_estate_agent",
  ACCOUNTANT = "accountant",
  BANKER = "banker",
  FAMILY_OFFICE = "family_office"
};

export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
  Other = "OTHER",
};

export enum Binding {
  Child = "CHILD",
  GrandChild = "GRAND-CHILD",
  NephewNiece = "NEPHEW-NIECE",
  Friend = "FRIEND",
  Other = "OTHER",
};

// SUBSCRIPTION RELATED
export interface UserConsumption {
  storage: number,
}

export interface UserSubscription {
  sku: string;
  payment: SubscriptionPayment;
  created_date: Date;
  end_date: Date;
  status?: SubscriptionStatus;
  cancelled_date?: Date;
}

// CLASSIC+KID COMMON
export interface UserSpace {
  type: string; // todo: define with SpaceType
  subscriptions: UserSubscription[],
  consumption: UserConsumption,
}

// CLASSIC RELATED
export interface Keyring {
  master_key: string,
  flag: string
}

export  interface ClassicUserSpace extends UserSpace {
  keyring: Keyring,
  status?: SpaceStatus
}

// KID RELATED
export interface Timing {
  start_date: Date;
  end_date: Date;
}
export interface Configuration {
  timings: Timing[],
  consumption_per_member: UserConsumption,
}

export interface KidInformation {
  first_name: string,
  last_name: string,
  dob: string,
  gender: Gender,
  binding: Binding,
}

export interface KidsUserSpace extends UserSpace {
  configuration: Configuration,
  information: KidInformation,
  status?: SpaceStatus,
}

// USER GLOBAL
export interface User {
  ciam_id: string,
  first_name: string;
  last_name: string;
  dob: string;
  phone: string;
  marketing_optin: boolean;
  type: UserType;
  email: string;

  spaces: {
    classic?: ClassicUserSpace,
    kids?: KidsUserSpace,
  }
};

export const isGuardian = async (): Promise<boolean> => {
  const wishbookers = await userApi.getWishbookers();
  return wishbookers && wishbookers.length > 0;
};

// Storage consumption
export const getUsedStorage = (user: User, space: SpaceType): number => {
  return user.spaces[space] ? user.spaces[space].consumption.storage : 0;
};

export const getTotalStorage = (user: User, space: SpaceType): number => {
  return user.spaces[space].subscriptions
      .filter((subscription: UserSubscription) => (subscription.status && subscription.status == SubscriptionStatus.ACTIVATED) || subscription.status == null)
      .map((subscription: UserSubscription) => getSubscriptionConfigurationForSku(subscription.sku)?.capacity?.storage)
      .reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0)
};

// Safe closing
export const getClassicClosingMoment = (user: User): moment.Moment => {
  let closingMoment = null;

  const subscriptionCopy = _.cloneDeep(user.spaces.classic.subscriptions);

  subscriptionCopy.reverse().forEach((userSubscription) => {
    /*if (userSubscription.sku === SubscriptionSkus.comWishbookClassicAddon1week) {
      closingMoment = moment(userSubscription.created_date).add(1, "week");
    } */
    if (userSubscription.sku === SubscriptionSkus.comWishbookClassic) {
      closingMoment = moment(userSubscription.created_date).add(1, "year");
    } else if (userSubscription.sku === SubscriptionSkus.comWishbookClassicFreemium) {
      closingMoment = moment(userSubscription.created_date).add(1, "month");
    }
  });

  return closingMoment;
};

// Subscriptions
export const userHasSubscriptionSku = (user: User, sku: string): boolean => {
  return [
    ...user.spaces && user.spaces.classic ? user.spaces.classic.subscriptions : [],
    ...user.spaces && user.spaces.kids ? user.spaces.kids.subscriptions : [],
  ].find((subscription) => subscription.sku === sku) != null;
}

// Access persmission
export enum AccessDenialCause {
  WishbookerClosedSafe = "WISHBOOKER_CLOSED_SAFE",
  WishbookerSubscriptionUpgradeRequired = "WISHBOOKER_SUBSCRIPTION_UPGRADE_REQUIRED",
  MemberNotAllowed = "MEMBER_NOT_ALLOWED",
}
interface AccessDetails {
  hasAccess: boolean,

  cause?: AccessDenialCause;
  requiredSkus?: string[],
}
export const canAccess = (context: PersistentContext, user: User, route: string, fromUser: User = null): AccessDetails => {

  // Member
  if (context.isNotMe()) {
    if (route.includes("classic/directives") && user.spaces.classic.subscriptions.find((sub) => sub.sku == SubscriptionSkus.comWishbookClassic || sub.sku == SubscriptionSkus.comWishbookClassicYearly)) return { hasAccess: true };
    return { hasAccess: false, cause: AccessDenialCause.MemberNotAllowed };
  }

  const correspondingSubscriptionConfigurations = defaultSubscriptionConfiguration.filter((subscriptionConfiguration) => subscriptionConfiguration.regexSafeAccess && subscriptionConfiguration.regexSafeAccess.map((regex) => route.match(regex)).some((resultMatch => resultMatch != null)))

  let userSubscriptions = [];
  if (user.spaces.kids) userSubscriptions = userSubscriptions.concat(user.spaces.kids.subscriptions);
  if (user.spaces.classic) userSubscriptions = userSubscriptions.concat(user.spaces.classic.subscriptions);
  const userSubscriptionSkus = userSubscriptions.map((userSubscription) => userSubscription.sku);

  // Wishbooker
  if (user.spaces.classic && user.spaces.classic.status == SpaceStatus.CLOSED) {
    const hasClassic = user.spaces.classic.subscriptions.find((sub) => sub.sku == SubscriptionSkus.comWishbookClassic) != null;
    const hasFreemium = user.spaces.classic.subscriptions.find((sub) => sub.sku == SubscriptionSkus.comWishbookClassicFreemium) != null;
    if (route.includes("classic/directives") && hasClassic == true) return { hasAccess: true };

    // todo: If freemium, we should indicate classic only.
    else return { hasAccess: false, cause: AccessDenialCause.WishbookerClosedSafe, requiredSkus: [SubscriptionSkus.comWishbookClassic] };
  }

  return {
    hasAccess: correspondingSubscriptionConfigurations.some((subConfiguration) => userSubscriptionSkus.includes(subConfiguration.sku)),
    cause: AccessDenialCause.WishbookerSubscriptionUpgradeRequired,
    requiredSkus: correspondingSubscriptionConfigurations.filter((subConfiguration) => !userSubscriptionSkus.includes(subConfiguration.sku)).map((subConfiguration) => subConfiguration.sku)
  };
};

export const diffSubscriptions = (user: User, spaceType: SpaceType) => {

  let userSubscriptions: UserSubscription[] = [];
  if (spaceType == SpaceType.CLASSIC) userSubscriptions = userSubscriptions.concat(user.spaces.classic.subscriptions);
  if (spaceType == SpaceType.KIDS) userSubscriptions = userSubscriptions.concat(user.spaces.kids.subscriptions);

  const classicSubscription = user.spaces.classic.subscriptions.find((sub) => sub.sku == SubscriptionSkus.comWishbookClassic);

  // Active & Expired user subscriptions
  const expiredUserSubscriptions: UserSubscription[] = [];
  const activeUserSubscriptions: UserSubscription[] = [];
  userSubscriptions.forEach((userSubscription) => {
    const subConfiguration = defaultSubscriptionConfiguration.find((subConfiguration) => subConfiguration.sku === userSubscription.sku);
    if (subConfiguration.duration != 0 && moment(userSubscription.created_date).add(subConfiguration.duration, "days").isBefore(moment())) {
      expiredUserSubscriptions.push(userSubscription);
    }
    else {
      activeUserSubscriptions.push(userSubscription);
    }
  });

  // Active & Expired user subscriptions SKUS
  const expiredUserSubscriptionSkus = expiredUserSubscriptions.map((expiredUserSubscription) => expiredUserSubscription.sku);
  //const activeUserSubscriptionSkus = activeUserSubscriptions.map((activeUserSubscription) => activeUserSubscription.sku);
  const activeUserSubscriptionSkus = activeUserSubscriptions
      .map((activeUserSubscription) => activeUserSubscription.sku)
      .filter((sku) =>
          !(sku === SubscriptionSkus.comWishbookClassicYearly && activeUserSubscriptions.some((sub) => sub.sku === SubscriptionSkus.comWishbookClassic))
      );

  // Available subscriptions
  const availableSubscriptionSkus = defaultSubscriptionConfiguration
    .filter((subConfiguration) => {
      if (subConfiguration.space == spaceType) {
        //if (activeUserSubscriptionSkus.includes(subConfiguration.sku)) return false;
        //if (subConfiguration.sku === SubscriptionSkus.comWishbookClassic) return false;

        if (subConfiguration.sku === SubscriptionSkus.comWishbookClassic && activeUserSubscriptionSkus.includes(subConfiguration.sku)) return false;
        if (subConfiguration.sku === SubscriptionSkus.comWishbookClassicFreemium) return false;
        if (subConfiguration.sku === SubscriptionSkus.comWishbookClassicYearly) return false;
        //if (subConfiguration.sku === SubscriptionSkus.comWishbookClassicAddonProfessional && activeUserSubscriptionSkus.includes(subConfiguration.sku)) return false;

        /*if (classicSubscription) {
          if (subConfiguration.sku === SubscriptionSkus.comWishbookClassicYearly) return false;
        }*/
        return true;
      }

      return false;
    })
    .map((subConfiguration) => subConfiguration.sku);

    console.log("Expired");
    console.log(expiredUserSubscriptionSkus);

    console.log("Active");
    console.log(activeUserSubscriptionSkus);

    console.log("Available");
    console.log(availableSubscriptionSkus);

  const uniqueActiveUserSubscriptionSkus = activeUserSubscriptionSkus.filter((sku, index, self) => self.indexOf(sku) === index);
  return {
    expiredUserSubscriptionSkus: expiredUserSubscriptionSkus,
    uniqueActiveUserSubscriptionSkus: uniqueActiveUserSubscriptionSkus,
    activeUserSubscriptionSkus: activeUserSubscriptionSkus,
    availableSubscriptionSkus: availableSubscriptionSkus,
  };
};

export default User;
