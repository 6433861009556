import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Avatar,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import {useTranslation} from "react-i18next";
import i18n from '../../../i18n';
import { changeColorAlpha } from '../../../utils/color';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2.5),
    padding: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    border: "solid",
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      background: changeColorAlpha(theme.palette.primary.main, 0.1) //"#fff4e1"
    },
  },

  userTypeLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  avatar: {
    width: 34,
    height: 34,
    backgroundColor: theme.palette.secondary.main,
  },
  guardianAvatar: {
    width: 34,
    height: 34,
    backgroundColor: theme.palette.primary.main,
  },
  textConnect: {
    fontSize: 12,
  }
}));

const User: FC = ({}) => {
  const classes = useStyles();
  const { context } = useAuth();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('navbar_space_tooltip')}>
      <Link
        className={classes.userTypeLink}
        to={`/${i18n.language}/space-select`}
      >
    <Box display="flex" flexDirection={{ sm: "row" }} className={classes.root}>
    <Box
      flex={{ xs: 1}}
      display="flex"
      alignItems="center"
    >
      <Avatar
          alt="User"
          className={context.isMe() ? classes.avatar : classes.guardianAvatar}
        />
    </Box>
    <Box flex={{ xs: 3}}>
      <Typography
            variant="subtitle2"
            color="textPrimary"
            className={classes.textConnect}
            >
        { context.isMe() ? t('navbar_connected_as') : t('navbar_access_to_safe') }
      </Typography>
      <Typography
          variant="h6"
          color="textPrimary"
      >
        {context.getCurrentSpace().user.first_name}{" "}
        {context.getCurrentSpace().user.last_name}
      </Typography>
    </Box>
  </Box>

      </Link>
    </Tooltip>
  );
};

export default User;
