import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';

import { GA_MEASUREMENT_ID } from "src/constants";

const GoogleAnalytics: FC = () => {
  return (
    <Helmet>
      {
        GA_MEASUREMENT_ID && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
          />
        )
      }
      {
        GA_MEASUREMENT_ID && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];

              function gtag() {
                dataLayer.push(arguments);
              }

              gtag('js', new Date());
              gtag('config', '${GA_MEASUREMENT_ID}');
            `}
          </script>
        )
      }
    </Helmet>
  );
};

export default GoogleAnalytics;
