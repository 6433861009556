import AuthModel from './model'
import { makeRequest } from '../common/client'

import { UMA_CLIENT_ID } from 'src/constants';

export const refresh = async (authModel: AuthModel): Promise<AuthModel> => {
  const refreshRequestData = {
    client_id: UMA_CLIENT_ID,
    grant_type: "refresh_token",
    refresh_token: authModel.refresh_token
  };
  const response = await makeRequest('POST', '/oauth/token', refreshRequestData);
  return response.data;
};

export const login = async (email: string, password: string): Promise<AuthModel> => {
  const loginRequestData = {
    username: email,
    password,
    client_id: UMA_CLIENT_ID,
    grant_type: "password"
  };
  const response = await makeRequest('POST', '/oauth/token', loginRequestData);
  return response.data;
};

export const logout = async (authModel: AuthModel): Promise<void> => {
  const logoutRequestData = {
    access_token: authModel.access_token,
    client_id: UMA_CLIENT_ID,
  };
  await makeRequest('POST', '/oauth/logout', logoutRequestData);
};

export const resetPassword = async (email: string): Promise<void> => {
  const resetPasswordRequestData = {
    email: email,
    client_id: UMA_CLIENT_ID,
  };
  await makeRequest('POST', '/actions/reset-password', resetPasswordRequestData);
};
