// const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

export function changeColorAlpha(hexColor, opacity) 
{
    //if it has an alpha, remove it
    if (hexColor.length > 7)
        hexColor = hexColor.substring(0, hexColor.length - 2);

    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase()

    // opacities near 0 need a trailing 0
    if (opacityHex.length == 1)
        opacityHex = "0" + opacityHex;

    return hexColor + opacityHex;
}