import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {Box, FormControl, InputLabel, makeStyles, MenuItem, Select} from '@material-ui/core';
import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import PassphraseViewDialog from 'src/views/classic/passphrase/PassphraseViewDialog';
import i18n from "i18next";

interface DashboardLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  languageSwitchBox: {
    position: 'absolute',
    zIndex: 200,
    top: '40px',
    color: '#fff',
    right: '10px',
    padding: theme.spacing(5)
  },
  selectLanguage: {
    '& .MuiInput-input' : {
      display: 'flex',
      alignItems: 'center',
    }
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const defaultLanguage = i18n.language;

  const changeLocale = () => {

    const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
    const pathname = window.location.pathname;
    const urlParts = pathname.split('/');
    const languageIndex = urlParts.findIndex(part => part === i18n.language);

    if (languageIndex > 0) {
      urlParts[languageIndex] = newLanguage;
      window.location.href = urlParts.join('/');
    }
  }

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Box className={classes.languageSwitchBox}>
              <FormControl>
                <Select
                    value={defaultLanguage}
                    onChange={changeLocale}
                    style={{color:'#fff'}}
                    className={classes.selectLanguage}
                >
                  <MenuItem value="en">
                    <span>EN</span>
                    <img src={'/static/images/flags/en.svg'} alt="EN" style={{width: '25px', height: '25px', marginLeft: '5px'}}/>
                  </MenuItem>
                  <MenuItem value="fr">
                    <span>FR</span>
                    <img src={'/static/images/flags/fr.svg'} alt="FR" style={{width: '25px', height: '25px', marginLeft: '5px'}}/>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {children}

            <PassphraseViewDialog />

          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
