import React from 'react';
import type { FC } from 'react';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  CssBaseline
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { IntercomProvider } from 'react-use-intercom';

import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';

import { AuthProvider } from 'src/contexts/AuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { INTERCOM_APP_ID } from "src/constants";

import { LocalizedRouter } from 'src/routes/LocalizedRouter';
import { buildNavigation } from 'src/routes/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();
  const theme = createTheme();

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
              dense
              maxSnack={3}
            >
              <LocalizedRouter>
                  <AuthProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    <GoogleAnalytics />

                    {/* <CookiesNotification /> */}

                    {buildNavigation()}
                  </AuthProvider>
              </LocalizedRouter>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </IntercomProvider>
  );
};

export default App;
