import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';

import NavHeader from './NavHeader';
import NavFooter from './NavFooter';
import renderNav from './NavEngineFunctions';
import buildNavigation from "./ApplicationNavigation";

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    backgroundColor: theme.palette.background.default,
    width: 256
  },
  desktopDrawer: {
    backgroundColor: theme.palette.background.default,
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
}));

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { context } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <NavHeader />
        <Divider />
        <Box pl={2} pr={2}>
          { renderNav(buildNavigation(context, t), location.pathname) }
        </Box>
        <Divider />
        <NavFooter />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
