import { SpaceType } from "src/contexts/AuthContext/Space";

export enum PaymentStatus {
  INITIATED = "INITIATED",
  PROCESSED = "PROCESSED",
};

export enum PaymentResult {
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
};

export enum SubscriptionPaymentProvider {
  STRIPE = "STRIPE",
  ALMA = "ALMA",
  WB = "WB",
}

export interface SubscriptionPaymentExternal {
  id: string;
  secret: string;
  url: string;
}

export interface SubscriptionPayment {
  status:PaymentStatus;
  result: PaymentResult;
  coupon: string;
  external: SubscriptionPaymentExternal;
  provider: SubscriptionPaymentProvider;
}

export enum SubscriptionStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
};
