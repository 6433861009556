import * as crypto from "crypto";
import _ from 'lodash';

import { WishbookKeyring, ALGO } from "./wishbook";

export const calculateCipherSize = (unencryptedSize: number): number => {
  return (unencryptedSize + 16) & ~15
}

export const calculateBas64Size = (unencryptedSize: number): number => {
  return (4 * Math.ceil(unencryptedSize / 3.0))
}

export const chunkSize: number = 1024 * 1024;
export const cipherChunkSize: number = calculateCipherSize(chunkSize);
export const base64ChunkSize: number = calculateBas64Size(cipherChunkSize);

export const encryptString = (source: string, keyring: WishbookKeyring) => {
  const sourceBuffer = Buffer.from(source, 'utf8');
  const cipher = crypto.createCipheriv(ALGO, keyring.masterAESKey.key, keyring.masterAESKey.iv);
  let result = cipher.update(sourceBuffer.toString('binary'), 'binary', 'base64');
  result += cipher.final('base64');
  return result;
};

export const decryptString = (source: string, keyring: WishbookKeyring) => {
  const sourceBuffer = Buffer.from(source, 'base64');
  const decipher = crypto.createDecipheriv(ALGO, keyring.masterAESKey.key, keyring.masterAESKey.iv);
  let result = decipher.update(sourceBuffer.toString("binary"), 'binary', 'utf8');
  result += decipher.final('utf8');

  return result;
};

export const encryptStructuredObject = (blob: any, keyring: WishbookKeyring) => {
  if (_.isArray(blob)) {
    return _.map(blob, (value) => encryptStructuredObject(value, keyring));
  }
  if (_.isPlainObject(blob)) {
    return _.mapValues(blob, (value, key) => {
      if (key.includes("file") || key.includes("id") || key.includes("date")) { return value; }
      return encryptStructuredObject(value, keyring);
    });
  }
  if (_.isString(blob)) {
    return encryptString(blob, keyring);
  }

  // Date type is not encrypted.
  // 1. Leave it that way and we'll know dates for marketing purposes
  // 2. Encrypt it by performing .toISOString on it first.

  return blob;
};

export const decryptStructuredObject = (blob: any, keyring: WishbookKeyring) => {

  if (_.isArray(blob)) {
    return _.map(blob, (value) => decryptStructuredObject(value, keyring));
  }
  if (_.isPlainObject(blob)) {
    return _.mapValues(blob, (value, key) => {
      if (key.includes("file") || key.includes("id") || key.includes("date")) { return value; }
      return decryptStructuredObject(value, keyring);
    });
  }
  if (_.isString(blob)) {
    try {
      return decryptString(blob, keyring);
    } catch(error) {
      console.log("UNABLE TO DECRYPT");
      return blob
    }
  }
  return blob;
};

export const encryptData = (data: Buffer, keyring: WishbookKeyring) => {
  const cipher = crypto.createCipheriv(ALGO, keyring.masterAESKey.key, keyring.masterAESKey.iv);
  let result = cipher.update(data.toString('binary'), 'binary', 'base64');
  result += cipher.final('base64');
  return result;
};

export const decryptData = (data: Buffer, keyring: WishbookKeyring) => {
  const decipher = crypto.createDecipheriv(ALGO, keyring.masterAESKey.key, keyring.masterAESKey.iv);
  let result = decipher.update(data.toString(), 'base64', 'binary');
  result += decipher.final("binary");

  console.log(`Decrypted data length: ${result.length}`);
  return Buffer.from(result, "binary");
};

export const encryptFile = (file: File, keyring: WishbookKeyring): ReadableStream => {
  const page = chunkSize;

  const stream = new ReadableStream({
    async start(controller) {
      for (let offset = 0; offset < file.size; offset += page) {
        const chunk = file.slice(offset, page+offset);
        console.log(`Chunk starting at ${offset}`);
        const bufferChunk = Buffer.from(await chunk.arrayBuffer());
        const encryptedChunk = encryptData(bufferChunk, keyring);
        controller.enqueue(encryptedChunk);
      }
    },
  });
  return stream;
};


export const decryptFile = (fileStream: ReadableStream, keyring: WishbookKeyring): ReadableStream => {
  const fileReader = fileStream.getReader();

  const decryptAndQueueData = (buff: Buffer, controller: ReadableStreamDefaultController): Buffer => {
    const slicedCache = buff.slice(0, base64ChunkSize);
    buff = buff.slice(base64ChunkSize);

    const encryptedValue = decryptData(slicedCache, keyring);
    controller.enqueue(encryptedValue);
    return buff;
  }

  const stream = new ReadableStream({
    start(controller) {

      let cacheBuffer: Buffer;
      function push() {

        fileReader.read().then(({done, value}) => {
          if (done) {
            cacheBuffer = decryptAndQueueData(cacheBuffer, controller);
            controller.close();
            return;
          }
          if (!cacheBuffer) {
            cacheBuffer = Buffer.from(value);
          } else {
            cacheBuffer = Buffer.concat([cacheBuffer, Buffer.from(value)]);
          }

          if (cacheBuffer.length >= base64ChunkSize || done) {
            cacheBuffer = decryptAndQueueData(cacheBuffer, controller);
          }
          push();
        });
      }
      push();
    },
  });
  return stream;
};

export const decryptImage = async (fileStream: ReadableStream, keyring: WishbookKeyring): Promise<Buffer> => {
  const fileReader = fileStream.getReader();

  const decryptAndQueueData = (cacheBuffer: Buffer, imageBufferList: Buffer[]): Buffer => {
    const slicedCache = cacheBuffer.slice(0, base64ChunkSize);
    const decryptedValue = decryptData(slicedCache, keyring);
    const decryptedBuffer = Buffer.from(decryptedValue)
    imageBufferList.push(decryptedBuffer)
    return cacheBuffer.slice(base64ChunkSize);
  }

  let cacheBuffer: Buffer;
  let imageBufferList: Buffer[] = [];
  
  async function push() {
    const { done, value } = await fileReader.read();
    if (done) {
      decryptAndQueueData(cacheBuffer, imageBufferList);
      return;
    }

    if (!cacheBuffer) {
      cacheBuffer = Buffer.from(value);
    } else {
      cacheBuffer = Buffer.concat([cacheBuffer, Buffer.from(value)]);
    }

    if (cacheBuffer.length >= base64ChunkSize) {
      cacheBuffer = decryptAndQueueData(cacheBuffer, imageBufferList);
    }
    await push();
  }
  await push();
  return Buffer.concat(imageBufferList);
};
