import React from 'react';
import type { ReactNode } from 'react';

import { matchPath } from 'react-router-dom';
import {
  List,
  ListSubheader,
} from '@material-ui/core';

import NavItem from './NavItem';

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface ReduceChildRoutesProps {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
};

function reduceChildRoutes({ acc, pathname, item, depth }: ReduceChildRoutesProps) {

  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, { path: item.href, exact: false });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        { renderNavItems({depth: depth + 1, pathname, items: item.items }) }
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

interface NavItemsRenderProps {
  items: Item[];
  pathname: string;
  depth?: number;
};

function renderNavItems({ items, pathname, depth = 0 }: NavItemsRenderProps) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  );
}

export interface Section {
  items: Item[];
  subheader: string;
}

export function renderNav(sections: Section[], pathname: string) {
  return sections.map((section) => (
    <List
      key={section.subheader}
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
        >
          {section.subheader}
        </ListSubheader>
      )}
    >
      { renderNavItems({ items: section.items, pathname }) }
    </List>
  ))
}

export default renderNav;
