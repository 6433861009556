import React from 'react';
import { Route, Redirect, BrowserRouter } from 'react-router-dom';

import { LanguageKeys, AVAILABLE_LANGUAGES_CONFIGURATIONS } from 'src/constants';
import i18n from 'src/i18n';

interface Props {
  // RouterComponent: React.ComponentClass<any>;
}

// const getRouterBaseName = () => {
//   console.log(`BASENAME => ${i18n.language}`);
//   return i18n.language ? `/${i18n.language}`: null;
// };

export const LocalizedRouter: React.FC<Props> = ({
  children,
  // RouterComponent,
}) => (
  <BrowserRouter>
    <Route
      path="/:lang([a-zA-Z]{2})"
      children={({ match, location }) => {

        // const defaultLanguage = LanguageKeys.English;
        const defaultLanguage = i18n.language;

        /**
         * Get current language
         * Set default locale to en if base path is used without a language
         */
        console.log(`Match route lang => ${match ? JSON.stringify(match.params) : 'null'} with location => ${JSON.stringify(location)}`);

        if (match && match.params && match.params.lang) {
          const lang = match.params.lang;

          if (AVAILABLE_LANGUAGES_CONFIGURATIONS.map(item => item.key).includes(match.params.lang) === true) {
            console.log(`Set i18n to ${lang}`);
            i18n.changeLanguage(lang);
            return (
              children
            );
          }
          console.log(`No lang param match: ${JSON.stringify(location)}`);
          const destination = location.pathname.replace(`/${match.params.lang}`, `/${defaultLanguage}`);
          return <Redirect to={destination} />;
        }

        return <Redirect to={`/${defaultLanguage}${location.pathname}`} />;

        // else {
        //   location.pathname = `/${defaultLanguage}${location.pathname}`;
        // }

        // // if (AVAILABLE_LANGUAGES_CONFIGURATIONS.map(item => item.key).includes(lang) == false) {
        // //   return <Redirect to={`/${lang}/`} />;
        // // }

        // /**
        //  * If language is not in route path, redirect to language root
        //  */

        // if (!location.pathname.includes(`/${lang}/`)) {
        //   return <Redirect to={`/${lang}/`} />;
        // }


        // /**
        //  * Return Intl provider with default language set
        //  */
        // return (
        //   children
        // );
      }}
      />
  </BrowserRouter>
);
