export async function readableStreamToBuffer(readableStream: ReadableStream): Promise<Buffer> {
  const fileReader = readableStream.getReader();
  let imageBufferList: Buffer[] = [];
  
  async function push() {
    const { done, value } = await fileReader.read();
    if (done) {
      return;
    } else {
      imageBufferList.push(Buffer.from(value));
      await push();
    }
  }
  await push();

  console.log(`List of buffer is ${imageBufferList.length}`);
  return Buffer.concat(imageBufferList);
};