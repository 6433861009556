import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getClassicClosingMoment, SpaceStatus } from '../api/wishbook/users/model';
import moment from 'moment';
import useAuth from '../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexWrap: 'nowrap',
  },
  timeItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 8px',
  },
  separator: {
    margin: '0 4px',
  },
  timeText: {
    fontSize: '1rem',
    lineHeight: '1.2',
  },
  labelText: {
    fontSize: '0.75rem',
    lineHeight: '1',
  },
}));

interface Counter {
  day: number,
  hour: number,
  minute: number
}

const Timer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { context } = useAuth();
  const [counter, setCounter] = useState<Counter>({day: 0, hour: 0, minute: 0})
  const safeClosed = context.getCurrentSpace().user.spaces.classic.status == SpaceStatus.CLOSED;

  useEffect(() => {
    const updateCounter = (): void => {
      const closingMoment = getClassicClosingMoment(context.getCurrentSpace().user);
      const now = moment();
      if (now < closingMoment) {
        const day = closingMoment.diff(now, "days");
        const hour = closingMoment.subtract(day, "days").diff(now, "hours");
        const minute = closingMoment.subtract(hour, "hours").diff(now, "minutes");

        setCounter({
          day,
          hour,
          minute
        })
      }
    }

    updateCounter();
    const interval = setInterval(updateCounter, 60000);

    return () => clearInterval(interval);
  }, [])


  if (safeClosed || (counter.day === 0 && counter.hour === 0 && counter.minute === 0)) return null;

  return (
    <Grid container className={classes.timerContainer}>
      <Grid item className={classes.timeItem}>
        <Typography className={classes.timeText}>{counter.day}</Typography>
        <Typography className={classes.labelText}>{t('home_counter_days')}</Typography>
      </Grid>
      <Typography className={classes.separator}>:</Typography>
      <Grid item className={classes.timeItem}>
        <Typography className={classes.timeText}>{counter.hour}</Typography>
        <Typography className={classes.labelText}>{t('home_counter_hours')}</Typography>
      </Grid>
      <Typography className={classes.separator}>:</Typography>
      <Grid item className={classes.timeItem}>
        <Typography className={classes.timeText}>{counter.minute}</Typography>
        <Typography className={classes.labelText}>{t('home_counter_minutes')}</Typography>
      </Grid>
    </Grid>
  );
};

export default Timer;
