import axios from 'axios';
import type { Method } from 'axios';

import { WISHBOOK_BASEURL } from 'src/constants';

export const axiosInstance = axios.create({ baseURL: WISHBOOK_BASEURL });
export default axiosInstance;

const logResponse = (response) => {
  console.log(response);

  if (response.config && response.config.url) {
    console.log(`[WSHBK] ${response.config.method} ${response.config.url} -> ${response.status}`);
  }

  if (response.config && response.config.data) {
    console.log('[WSHBK] Sending');
    console.log(response.config.data);
  } 
  if (response.data) {
    console.log('[WSHBK] Receiving');
    console.log(response.data);
  }
};

const logError = (error) => {
  if (error.response && error.response.config && error.response.config.url) {
    console.log(`[WSHBK] ${error.response.config.method} ${error.response.config.url} -> ${error.response.status}`);
  }
  
  if (error.response) {
    console.log(error.response);
    if (error.response.config && error.response.config.data) {
      console.log('[WSHBK] Sending');
      console.log(error.response.config.data);
    }
    if (error.response.data) {
      console.log('[WSHBK] Receiving');
      console.log(error.response.data);
    }  
  }
};

export async function makeRequest(method: Method, url: string, data: any = null): Promise<any> {
  try {
    const response = await axiosInstance({
      method: method,
      url: url,
      data: data,
    });
    logResponse(response);
    return response;
  }
  catch (error) {
    logError(error);
    throw error;
  }
}

export const assignDefaultAuthorizationBearerToken = (accessToken: string) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};
