import React from 'react';
import clsx from 'clsx';
import type { FC } from 'react';
import {
  Link,
  makeStyles,
} from '@material-ui/core';

import {
  WEBSITE_BASE_URL
} from 'src/constants';

interface LogoProps {
  className?: string
}

const useStyles = makeStyles(() => ({
  logo: {
    height: "93px",
  }
}));

const Logo: FC<LogoProps> = ({className, ...rest}) => {
  const classes = useStyles();
  return (
    <Link
      href={WEBSITE_BASE_URL}
    >
      <img
        className={clsx(classes.logo, className)}
        alt="Logo"
        src="/static/logo_black.svg"
        {...rest}
      />
    </Link>
  );
}

export default Logo;
