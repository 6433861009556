import axios from 'axios';
import type { Method } from 'axios';

import { UMA_BASEURL } from 'src/constants';

const axiosInstance = axios.create({ baseURL: UMA_BASEURL });

const logResponse = (response) => {
  if (response.config.data) {
    console.log('[UMA] Sending');
    console.log(response.config.data);
  }
  console.log(`[UMA] ${response.config.method} ${response.config.url} -> ${response.status}`);
  if (response.data) {
    console.log('[UMA] Receiving');
    console.log(response.data);
  }
};

const logError = (error) => {
  if (error.response.config.data) {
    console.log('[UMA] Sending');
    console.log(error.response.config.data);
  }
  console.log(`[UMA] CATCH ${error.request.method} ${error.request.url} -> ${error.response.status}`);
  if (error.response.data) {
    console.log('[UMA] Receiving');
    console.log(error.response.data);
  }
};

export async function makeRequest(method: Method, url: string, data: any = null): Promise<any> {
  try {
    const response = await axiosInstance({
      method: method,
      url: url,
      data: data
    });
    logResponse(response);
    return response;
  }
  catch (error) {
    logError(error);
    throw error;
  }
}

export const assignDefaultAuthorizationBearerToken = (accessToken: string) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

