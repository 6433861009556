import React from 'react';
import { Switch, RouteProps } from 'react-router';
import i18n from 'src/i18n';

export const LocalizedSwitch: React.FC = ({ children }) => {
  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(path?: string | string[]) {
    let finalPath = path;
    // console.log(`Initial path => ${path}`);
    const loadedLanguage = i18n.language ? i18n.language : i18n.languages[0];

    if (path instanceof Array) {
      finalPath = path.map((section) => `/${loadedLanguage}${section}`);
    }
    else if (path === "*") {
      finalPath = path;
    }
    else {
      finalPath = `/${loadedLanguage}${path}`;
    }

    // console.log(`Final path =>  ${finalPath}`);
    return finalPath;
  }

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, (child) =>
        React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.props.path),
            })
          : child
      )}
    </Switch>
  );
};
