import React from 'react';
import clsx from 'clsx';
import type { FC } from 'react';
import {
  makeStyles,
  Box
} from '@material-ui/core';
import type {Theme} from 'src/theme';

import WarningIcon from '@material-ui/icons/Warning';

interface NavIconProps {
  className?: string,
  path?: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: "24px",
  }
}));

const NavIcon: FC<NavIconProps> = ({className, path, ...rest}) => {
  const classes = useStyles();
  return (
    <div>
      <Box pr={0.5} pt={0.5}>
        <img
          className={clsx(classes.logo, className)}
          alt="Icon"
          src={path}
          {...rest}
        />
        <span>&nbsp;</span>
      </Box>
    </div>
  );
}

export const HomeIcon: FC = () => <NavIcon path="/static/images/navIcons/home-icon.svg"/>;
export const SubscriptionsIcon: FC = () => <NavIcon path="/static/images/navIcons/subscriptions-icon.svg"/>;
export const MembersIcon: FC = () => <NavIcon path="/static/images/navIcons/members-icon.svg"/>;

export const DocumentsIcon: FC = () => <NavIcon path="/static/images/navIcons/documents-icon.svg"/>;
export const MemoriesIcon: FC = () => <NavIcon path="/static/images/navIcons/memories-icon.svg"/>;

// Classic
export const FuneralsIcon: FC = () => <NavIcon path="/static/images/navIcons/funerals-icon.svg"/>;
export const CredentialsIcon: FC = () => <NavIcon path="/static/images/navIcons/credentials-icon.svg"/>;
export const DirectivesIcon: FC = () => <NavIcon path="/static/images/navIcons/directives-icon.svg"/>;
export const TestamentIcon: FC = () => <NavIcon path="/static/images/navIcons/testaments-icon.svg"/>;

// Kids
export const SavingsIcon: FC = () => <NavIcon path="/static/images/navIcons/savings-icon.svg"/>;
export const HealthIcon: FC = () => <NavIcon path="/static/images/navIcons/health-icon.svg"/>;
export const PotIcon: FC = () => <NavIcon path="/static/images/navIcons/pot-icon.svg"/>;
export const TimelapseIcon: FC = () => <NavIcon path="/static/images/navIcons/timelapse-icon.svg"/>;


export const NotificationIcon: FC = () => <WarningIcon style={{ color: 'red' }}/>
