import { MetaConfiguration } from 'src/api/wishbook/meta/MetaConfiguration';
import { WishbookKeyring } from "src/utils/crypto/wishbook";
import { User } from 'src/api/wishbook/users/model';
import { Wishbooker } from 'src/api/wishbook/member/model';
import Onboarding from 'src/api/wishbook/onboarding/model';

export enum SpaceType {
  CLASSIC = "classic",
  KIDS = "kids",
}

export class Space {
  type: SpaceType;
  user: User; // Is Wishbooker that named me Member or is Me.
  wishbookers: Wishbooker[];
  mc: MetaConfiguration;
  keyring: WishbookKeyring;
  onboardings: Onboarding[];

  shouldReachMembers: boolean;

  constructor(type: SpaceType, onboardings: Onboarding[], user: User, wishbookers: Wishbooker[], mc: MetaConfiguration, keyring: WishbookKeyring = null, shouldReachMembers: boolean = false) {
    this.onboardings = onboardings;
    this.user = user;
    this.type = type;
    this.wishbookers = wishbookers;
    this.mc = mc;
    this.keyring = keyring;
    this.shouldReachMembers = shouldReachMembers;
  }

  toJson() {
    return {
      type: this.type,
      onboarding: this.onboardings,
      user: this.user,
      wishbookers: this.wishbookers,
      mc: this.mc ? this.mc.toJson() : this.mc,
      keyring: this.keyring ? this.keyring.toJson() : this.keyring,
      shouldReachMembers: this.shouldReachMembers,
    }
  }

  static fromJson(payload) {
    if (payload == null) { return null; }
    return new Space(
      payload.type,
      payload.onboardings,
      payload.user,
      payload.wishbookers,
      MetaConfiguration.fromJson(payload.mc),
      WishbookKeyring.fromJson(payload.keyring),
      payload.shouldReachMembers,
    )
  }
}
