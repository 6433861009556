import React from "react";
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Button,
} from '@material-ui/core';
import { ButtonProps } from "@material-ui/core/Button";

interface MainButtonProps extends ButtonProps {
  isProcessing?: boolean;
  title: string;
  icon?: React.ReactNode;
}

const MainButton: FC<MainButtonProps> = ({isProcessing, title, icon, endIcon, ...rest}) => {

  return (
    <Button
      color="primary"
      variant="contained"
      size="large"
      startIcon={isProcessing ? null : icon}
      endIcon={isProcessing ? null : endIcon}
      {...rest}
    >
    {
      isProcessing != null && isProcessing === true ? <CircularProgress size={20} color="secondary"/> : title
    }
    </Button>
  );
};

MainButton.propTypes = {
  title: PropTypes.string,
  isProcessing: PropTypes.bool,
};

export default MainButton;
