import { SpaceType } from "src/contexts/AuthContext/Space";

// Configuration
export type SubscriptionPSP = "STRIPE"|"ALMA"|"WB";
export interface SubscriptionCapacity {
  storage: number;
  members: number;
}
export interface SubscriptionConfiguration {
  sku: string;
  space: SpaceType;
  duration: number;
  capacity: SubscriptionCapacity;
  regexSafeAccess?: RegExp[];
  providers: SubscriptionPSP[],
}


export enum SubscriptionSkus {
  comWishbookClassic = "com.wishbook.classic",
  comWishbookClassicFreemium = "com.wishbook.classic.freemium",
  comWishbookClassicAddon3guardians = "com.wishbook.classic.addon.3guardians",
  comWishbookClassicAddon1week = "com.wishbook.classic.addon.1week",
  comWishbookClassicAddon2go = "com.wishbook.classic.addon.2go",
  //comWishbookClassicAddonProfessional = "com.wishbook.classic.addon.professional",
  //comWishbookClassicMonthly = "com.wishbook.classic.monthly",
  comWishbookClassicYearly = "com.wishbook.classic.yearly",

  comWishbookKidsPremium = "com.wishbook.kids.premium",
  comWishbookKidsBasic = "com.wishbook.kids.basic",
  comWishbookKidsAddon2go = "com.wishbook.kids.addon.2go",
  comWishbookKidsAddon1week = "com.wishbook.kids.addon.1week",
}

export const getSubscriptionConfigurationForSku = (sku: string): SubscriptionConfiguration => {
  return defaultSubscriptionConfiguration.find((subscription) => subscription.sku === sku);
};

export const getStorageSubscriptionAddonSkuForSpace = (spaceType: SpaceType): SubscriptionSkus => spaceType == SpaceType.CLASSIC ? SubscriptionSkus.comWishbookClassicAddon2go : SubscriptionSkus.comWishbookKidsAddon2go

export const defaultSubscriptionConfiguration: SubscriptionConfiguration[] = [
  {
    sku: SubscriptionSkus.comWishbookClassic,
    space: SpaceType.CLASSIC,
    regexSafeAccess: [/(classic\/).*/],
    providers: ["STRIPE", "ALMA"],
    duration: 0,
    capacity: {
      storage: 2000000000,
      members: 3,
    },
  },
  {
    sku: SubscriptionSkus.comWishbookClassicFreemium,
    space: SpaceType.CLASSIC,
    regexSafeAccess: [/(classic\/funerals)/, /(classic\/documents)/],
    providers: ["WB"],
    duration: 30,
    capacity: {
      storage: 1000000000,
      members: 3,
    },
  },
  //{
  //  sku: SubscriptionSkus.comWishbookClassicMonthly,
  //  space: SpaceType.CLASSIC,
  //  regexSafeAccess: [/(classic\/).*/],
  //  providers: ["STRIPE"],
  //  duration: 30,
  //  capacity: {
  //    storage: 2000000000,
  //    members: 3,
  //  },
  //},*/
  {
    sku: SubscriptionSkus.comWishbookClassicYearly,
    space: SpaceType.CLASSIC,
    regexSafeAccess: [/(classic\/).*/],
    providers: ["STRIPE"],
    duration: 365,
    capacity: {
      storage: 2000000000,
      members: 3,
    },
  },
  {
    sku: SubscriptionSkus.comWishbookClassicAddon3guardians,
    space: SpaceType.CLASSIC,
    providers: ["STRIPE"],
    duration: 0,
    capacity: {
      storage: 0,
      members: 3,
    },
  },
  /*{
    sku: SubscriptionSkus.comWishbookClassicAddon1week,
    space: SpaceType.CLASSIC,
    providers: ["STRIPE"],
    duration: 7,
    capacity: {
      storage: 0,
      members: 0,
    },
  },*/
  {
    sku: SubscriptionSkus.comWishbookClassicAddon2go,
    space: SpaceType.CLASSIC,
    providers: ["STRIPE"],
    duration: 0,
    capacity: {
      storage: 2000000000,
      members: 0,
    },
  },
  /*{
    sku: SubscriptionSkus.comWishbookClassicAddonProfessional,
    space: SpaceType.CLASSIC,
    providers: ["STRIPE"],
    duration: 0,
    capacity: {
      storage: 0,
      members: 1,
    },
  },*/
  {
    sku: SubscriptionSkus.comWishbookKidsPremium,
    space: SpaceType.KIDS,
    regexSafeAccess: [/(kids\/).*/],
    providers: ["STRIPE", "ALMA"],
    duration: 0,
    capacity: {
      storage: 6000000000,
      members: 0,
    },
  },
  {
    sku: SubscriptionSkus.comWishbookKidsBasic,
    space: SpaceType.KIDS,
    regexSafeAccess: [/(kids\/albums)/, /(kids\/documents)/],
    providers: ["STRIPE", "ALMA"],
    duration: 0,
    capacity: {
      storage: 3000000000,
      members: 0,
    },
  },
  {
    sku: SubscriptionSkus.comWishbookKidsAddon2go,
    space: SpaceType.KIDS,
    providers: ["STRIPE"],
    duration: 0,
    capacity: {
      storage: 2000000000,
      members: 0,
    },
  },
  {
    sku: SubscriptionSkus.comWishbookKidsAddon1week,
    space: SpaceType.KIDS,
    providers: ["STRIPE"],
    duration: 7,
    capacity: {
      storage: 0,
      members: 0,
    },
  },
];
