import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    cursor: "pointer",
    height: 20,
  },
}));


export const FollowUs: FC = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  
  // Social
  const handleFacebook = () => { window.open('https://www.facebook.com/wishbookworld', "_blank"); };
  const handleInstagram = () => { window.open('https://www.instagram.com/wishbook_company', "_blank"); };
  const handleTwitter = () => { window.open('https://twitter.com/wishbook_world', "_blank"); };
  const handleYoutube = () => { window.open('https://www.youtube.com/channel/UCIADY_GtHmtcblSAxJ4zcLw', "_blank"); };

  return (
    <>
      <Typography
        variant="h6"
        color="textPrimary"
      >
        {t("footer_follow_us")}
      </Typography>
      <Box mt={1} />
      <Grid container spacing={2}>
        <Grid item><img className={classes.socialIcon} src={"/static/images/social/facebook.svg"} onClick={handleFacebook}/></Grid>
        <Grid item><img className={classes.socialIcon} src={"/static/images/social/instagram.svg"} onClick={handleInstagram}/></Grid>
        <Grid item><img className={classes.socialIcon} src={"/static/images/social/twitter.svg"} onClick={handleTwitter}/></Grid>
        <Grid item><img className={classes.socialIcon} src={"/static/images/social/youtube.svg"} onClick={handleYoutube}/></Grid>
      </Grid>
    </>
  );
};

export default FollowUs;