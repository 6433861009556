import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Avatar,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18n from 'src/i18n';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import { SpaceType } from 'src/contexts/AuthContext/Space';
import { userHasSubscriptionSku } from 'src/api/wishbook/users/model';
import { SubscriptionSkus } from 'src/api/wishbook/subscriptions/helper';
import { changeColorAlpha } from 'src/utils/color';

const useStyles = (color) => makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2.5),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    borderColor: color ? color : theme.palette.primary.main,
    borderWidth: 1,
    border: "solid",
  },
  avatar: {
    width: 34,
    height: 34,
    backgroundColor: color ? color : theme.palette.secondary.main,
  },
  status: {
    // color: theme.palette.primary.contrastText,
    color: color ? color : theme.palette.secondary.main,
  },
  userTypeLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  spaceTitle: {
    color: color ? color : theme.palette.secondary.main,
  },
  textConnect: {
    fontSize: 12,
  },
}));

const Space: FC = ({ }) => {
  const { context } = useAuth();
  const { t } = useTranslation();
  const isFreemiumOnly = userHasSubscriptionSku(context.getCurrentSpace().user, SubscriptionSkus.comWishbookClassicFreemium) && userHasSubscriptionSku(context.getCurrentSpace().user, SubscriptionSkus.comWishbookClassic) === false

  const classes = useStyles(
    isFreemiumOnly ?
    "#01989e" :
    null
  )();

  const getSpaceTitle = () => {
    if (context.getCurrentSpace().type == SpaceType.KIDS) return "Kids";
    return isFreemiumOnly ? "Freemium" : "Classic";
  };

  if (context.isNotMe())
    return null

  return (

        <Box display="flex" flexDirection={{ sm: "row" }} className={classes.root}>
       <Box
           flex={{ xs: 1 }}
           display="flex"
           alignItems="center"
       >
         <Avatar
             alt="User"
             className={classes.avatar}
         >
           {
             context.getCurrentSpace().type == SpaceType.CLASSIC ? <BusinessCenterIcon /> : <EmojiPeopleIcon />
           }
         </Avatar>
       </Box>
       <Box flex={{ xs: 3 }}>
         <Typography
             variant="subtitle2"
             color="textPrimary"
             className={classes.textConnect}
         >
           {t('navbar_current_plan')}
         </Typography>

           <Typography
               variant="h6"
               color="textPrimary"
           >
             {
               getSpaceTitle()
             }
           </Typography>

           <Typography
               variant="h6"
               color="textPrimary"
               className={classes.status}
           >
             {
               context.isMe() ? t('navbar_status_wishbooker') : t('navbar_status_guardian')
             }
           </Typography>

       </Box>

     </Box>


  );
};

export default Space;
