import { AuthModel, decodeTokens } from 'src/api/uma/auth/model';
import {MemberType, User} from 'src/api/wishbook/users/model';

import { MetaConfiguration } from 'src/api/wishbook/meta/MetaConfiguration';
import { Space, SpaceType } from "./Space"

import * as onboardingApi from "src/api/wishbook/onboarding/api";
import * as metaApi from "src/api/wishbook/meta/api";
import * as userApi from 'src/api/wishbook/users/api';
import { WishbookKeyring } from 'src/utils/crypto/wishbook';

const WISHBOOK_CONTEXT_KEY = 'wishbook-context';

export class PersistentContext {
  authentication: AuthModel;
  // user: User; // Is always me
  mySpace: Space;
  space: Space;
  hasSelectedSpace: boolean;

  constructor(authentication: AuthModel, mySpace: Space, space: Space, hasSelectedSpace: boolean = false) {
    this.authentication = authentication;
    this.mySpace = mySpace;
    this.space = space;
    this.hasSelectedSpace = hasSelectedSpace;
  }

  isMe(): boolean { // Means that the user is the owner of the space
    if (this.space) {
      return false;
    }
    return true;
  }

  isNotMe(): boolean { return !this.isMe(); }

  isMemberProfessional(): boolean { return this.mySpace.wishbookers[0]?.member.type == MemberType.PROFESSIONAL }

  getTargetUserId(): string { return this.isMe() ? "me" : this.space.user.ciam_id; }

  getCurrentSpace(): Space { return this.isMe() ? this.mySpace : this.space; }

  isUsingEncryption(): boolean { return this.getCurrentSpace().type == SpaceType.CLASSIC; }

  async activateContext(spaceType: SpaceType, keyring: WishbookKeyring = null) {
    const [user, onboardings, wishbookers, metaList] = await Promise.all([userApi.getMe(), onboardingApi.getAll(), userApi.getWishbookers(), metaApi.getMetaList("me")]);
    this.mySpace = new Space(spaceType, onboardings, user, wishbookers, new MetaConfiguration(metaList), keyring);
    this.space = null;
    this.save();
  }

  async refreshMySpace() {
    if (this.mySpace == null) return this.loadMySpace();

    const [user, onboardings, wishbookers, metaList] = await Promise.all([userApi.getMe(), onboardingApi.getAll(), userApi.getWishbookers(), metaApi.getMetaList("me")]);
    this.mySpace = new Space(this.mySpace.type, onboardings, user, wishbookers, new MetaConfiguration(metaList), this.mySpace.keyring);
    this.save();
  }

  async loadMySpace(spaceType: SpaceType = null) {
    const [user, onboardings, wishbookers, metaList] = await Promise.all([userApi.getMe(), onboardingApi.getAll(), userApi.getWishbookers(), metaApi.getMetaList("me")]);

    if (spaceType == null) {
      if (user.spaces && user.spaces.kids) spaceType = SpaceType.KIDS;
      else if (user.spaces && user.spaces.classic) spaceType = SpaceType.CLASSIC;
    }

    this.mySpace = new Space(spaceType, onboardings, user, wishbookers, new MetaConfiguration(metaList), null);
    this.save();
  }

  async loadSpaceForUser(spaceType: SpaceType, user: User) {
    const [metaList] = await Promise.all([metaApi.getMetaList(user.ciam_id)]);
    this.space = new Space(spaceType, [], user, [], new MetaConfiguration(metaList), null);
    this.save();
  }

  async resetSpaces() {
    this.mySpace = null;
    this.space = null;
    this.save();
  }

  toJson() {
    return {
      authentication: this.authentication,
      mySpace: this.mySpace ? this.mySpace.toJson() : null,
      space: this.space ? this.space.toJson() : null,
      hasSelectedSpace: this.hasSelectedSpace,
    };
  }

  static fromJson(payload) {
    if (payload == null) { return new PersistentContext(null, null, null); }
    const context = new PersistentContext(
      payload.authentication,
      Space.fromJson(payload.mySpace),
      Space.fromJson(payload.space),
      payload.hasSelectedSpace
    )
    console.log(context);
    return context;
  }

  static load() {
    const localStoragePayload = localStorage.getItem(WISHBOOK_CONTEXT_KEY);
    const persistentContext = PersistentContext.fromJson(localStoragePayload != null ? JSON.parse(localStoragePayload) : null);
    decodeTokens(persistentContext.authentication);
    console.log("[PersistenContext] Context is loaded");
    console.log(persistentContext);
    return persistentContext;
  }

  reset() {
    this.authentication = null;
    this.mySpace = null;
    this.space = null;
    this.hasSelectedSpace = false;
  }

  save() {
    console.log("Saving context");
    console.log(this);
    localStorage.setItem(WISHBOOK_CONTEXT_KEY, JSON.stringify(this.toJson()));
  }

  remove() {
    localStorage.removeItem(WISHBOOK_CONTEXT_KEY);
  }
}

