import { makeRequest } from '../common/client';
import { User } from 'src/api/wishbook/users/model';
import { Wishbooker } from 'src/api/wishbook/member/model';
import { PersistentContext } from "src/contexts/AuthContext/PersistentContext";

const getUserForId = async (userId: string): Promise<User> => {
    const response = await makeRequest('GET', `/api/v1/users/${userId}`);
    if (response.data.first_name) {
        response.data.first_name = response.data.first_name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    }
    if (response.data.last_name) {
        response.data.last_name = response.data.last_name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    }
    return response.data;
};

export const getUser = async(context: PersistentContext): Promise<User> => {
    const user = await getUserForId(context.getTargetUserId());
    return user;
};

export const getMe = async(): Promise<User> => {
    const user = await getUserForId("me");
    return user;
};

export const getWishbookers = async (): Promise<Array<Wishbooker>> => {
    const response = await makeRequest('GET', `/api/v1/users/me/wishbookers`);
    return response.data.wishbookers;
}

export const isGuardianRegistered = async (email: string): Promise<boolean> => {
    //const url = `/api/v1/users/me/is_guardian/${email}`;
    const url = `/api/v1/status/is_guardian?email=${encodeURIComponent(email)}`;
    const response = await makeRequest('GET', url);
    return response.data.is_guardian;
}

export default getUser;
