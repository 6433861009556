import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Hidden,
  makeStyles,
} from '@material-ui/core';

import type { Theme } from 'src/theme';
import Logo from 'src/components/LogoBlack';

import User from "./User";
import Space from "./Space";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: "38px",
  },
}));

const NavHeader: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Hidden lgUp>
        <Box
          p={2}
          display="flex"
          justifyContent="center"
        >
          <Logo className={classes.logo} />
        </Box>
      </Hidden>

      <User />
      <Space />
    </>
  );
};

export default NavHeader;