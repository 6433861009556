import React, {
  createContext,
  useEffect,
  useState
} from 'react';
import type { FC, ReactNode } from 'react';
import _ from 'lodash';
import { LanguageConfiguration, AVAILABLE_LANGUAGES_CONFIGURATIONS, LanguageNames} from 'src/constants';
import i18n from 'src/i18n';

const WISHBOOK_SETTINGS_KEY = "wishbook-settings";
interface Settings {
  language? : LanguageConfiguration;
}
const defaultSettings: Settings = { language: AVAILABLE_LANGUAGES_CONFIGURATIONS[0] };

/*
** Settings Context
*/
export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => { }
});

interface SettingsProviderProps {
  settings?: Settings;
  children?: ReactNode;
}

export const SettingsProvider: FC<SettingsProviderProps> = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState<Settings>(settings || defaultSettings);

  const storeSettings = (settings: Settings): void => {
    window.localStorage.setItem(WISHBOOK_SETTINGS_KEY, JSON.stringify(settings));
  };

  const handleSaveSettings = (update: Settings = {}): void => {
    const mergedSettings = _.merge({}, currentSettings, update);

    if (update.language.name === LanguageNames.French) i18n.changeLanguage('fr');
    else i18n.changeLanguage('en');

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  const restoreSettings = (): Settings | null => {
    let settings = null;
    try {
      const storedData: string | null = window.localStorage.getItem(WISHBOOK_SETTINGS_KEY);
      if (storedData) { settings = JSON.parse(storedData); }
    } catch (err) { console.error(err); }
    return settings;
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
