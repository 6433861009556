import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { translations as french } from 'src/locales/french'
import { translations as english } from 'src/locales/english'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    lng: "fr",
    fallbackLng: 'fr',
    resources: {
      en: { translation: english },
      fr: { translation: french },
    },
    debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;