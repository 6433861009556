import { v4 as uuidv4 } from 'uuid';

import { Meta } from 'src/api/wishbook/meta/model';
import { MetaType } from 'src/api/wishbook/meta/MetaConfiguration';
import { WFolder } from "src/api/wishbook/safes/common/WFolder"

export interface MetaDocuments extends Meta {
  content: {
    folders: {
      [prop: string]: WFolder;
    }
  }
}

export const defaultDocuments = (metaType: MetaType): MetaDocuments => {
  return {
    content: {
      folders: { }
    },
    type: metaType,
  };
}

const createSlug = (title) => {
  return title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9\s-]/g, '')
      .trim()
      .replace(/\s+/g, '-');
};

export const addFolder = (documents: MetaDocuments, folder: WFolder, isProfessional: boolean = false) => {
  const key = uuidv4();
  const title = createSlug(folder.title);

  isProfessional ? documents.content.folders[title] = {...folder, subfolders: {}} : documents.content.folders[key] = folder;

  if (folder.subfolders && Array.isArray(folder.subfolders)) {
    folder.subfolders.forEach((subfolder: WFolder) => {
      const subfolderKey = uuidv4();
      const subfolderTitle = createSlug(subfolder.title);
      isProfessional ? documents.content.folders[title].subfolders[subfolderTitle] = subfolder : documents.content.folders[key].subfolders[subfolderKey] = subfolder;
    })
  }
};

export const deleteFolder = (documents: MetaDocuments, folderKey: string) => {
  delete documents.content.folders[folderKey];
};
