import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Link,
  Divider,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import FollowUs from "./FollowUs";
import useAuth from 'src/hooks/useAuth';

import * as contants from "src/constants";

const NavHeader: FC = () => {
  const intercom = useIntercom();
  const { t } = useTranslation();
  const { context } = useAuth();

  const handleSupportRequest = () => {
    // intercom.show();
  };

  return (
    <>
      <Box p={2}>
        <Box
          p={2}
          borderRadius="borderRadius"
          bgcolor="secondary.light"
        >
          <Box display="flex" justifyContent="flex-inline">
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ fontWeight: 600 }}
            >
              {t('navbar_question')}
            </Typography>
            <Box pl={2}>
              <img src='/static/images/various/contact-us.svg' />
            </Box>
          </Box>
          <Link
            variant="subtitle2"
            color="secondary"
            style={{ fontWeight: 600 }}
            href={`mailto:${contants.SUPPORT_EMAIL}`}
            // onClick={handleSupportRequest}
          >
            {t('navbar_contact_us')}
          </Link>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>
        <FollowUs />
        <Typography variant="body2">
          © {new Date().getFullYear()} - Wishbook
        </Typography>
      </Box>
    </>
  );
};

export default NavHeader;