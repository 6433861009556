import jwtDecode from 'jwt-decode';

export interface AuthModel {
  access_token: string,
  refresh_token: string,
  id_token: string,
  scope: string,
  expires_in: number,
  decodedAccessToken?: any,
  decodedIdToken?: any
};

export const decodeTokens = (authModel: AuthModel): AuthModel => {
  if (authModel == null) {
    return null;
  }
  if (authModel.decodedAccessToken && authModel.decodedIdToken) {
    return authModel;
  }
  authModel.decodedAccessToken = authModel.access_token ? jwtDecode(authModel.access_token) : null;
  authModel.decodedIdToken = authModel.id_token ? jwtDecode(authModel.id_token) : null;
  return authModel;
};

export const isValid = (authModel: AuthModel): boolean => {
  if (authModel == null || authModel.decodedAccessToken == null) {
    return false;
  }
  return authModel.decodedAccessToken.exp > Date.now() / 1000;
};

export const hasEmailConfirmed = (authModel: AuthModel): boolean => {
  if (authModel == null || authModel.decodedIdToken == null) {
    return false;
  }
  return !!authModel.decodedIdToken.email_verified;
};

export const extractEmail = (authModel: AuthModel): string => {
  return authModel.decodedIdToken.email != null ?
    authModel.decodedIdToken.email :
    authModel.decodedIdToken.name;
}

export default AuthModel;
