import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet, Font
} from '@react-pdf/renderer';
// import type { Invoice } from 'src/types/invoice';
import { Onboarding } from 'src/api/wishbook/onboarding/model';
// @ts-ignore
import font from "./Roboto-Medium.ttf";
import { useTranslation } from 'react-i18next';

interface Profile {
  first_name: string;
  last_name: string;
  dob: string;
}

interface PassphrasePDFProps {
  passphrase: string,
  // onboarding?: Onboarding,
  profile: Profile,
}

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;

Font.register({ family: 'Roboto', src: font });

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    padding: 24
  },
  h1: {
    fontSize: 20,
    fontWeight: 500
  },
  h5: {
    fontSize: 12,
    fontWeight: 500
  },
  h6: {
    fontSize: 10,
    fontWeight: 500,
  },
  passphrase: {
    fontFamily: "Roboto",
    fontSize: 11,
    fontWeight: 500,
    borderTop: 0.5,
    borderBottom: 0.5,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 100,
    paddingRight: 100,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  body1: {
    fontSize: 9,
    lineHeight: 1.5
  },
  body2: {
    fontSize: 8,
    lineHeight: 1.5
  },
  mb1: {
    marginBottom: 4
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  brand: {
    height: 38,
  },
  company: {
    marginTop: 32,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  references: {
    marginTop: 32,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  items: {
    marginTop: 32
  },
  alignRight: {
    textAlign: 'right'
  },
  word: {
    marginRight: 5,
  },
});

const PassphrasePDF: FC<PassphrasePDFProps> = ({ passphrase, profile }) => {
  const {t} = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image source="/static/logo_black.jpg" style={styles.brand} />
          </View>
          <View>
            <Text style={styles.h1}>
              {t('passphrase_title')}
            </Text>
            <Text style={styles.h5}>
              {`Le ${moment(new Date()).format('DD MMM YYYY')}`}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.h5}>
          {t('passphrase_wishbook_baseline')}
          </Text>
        </View>
        <View style={styles.references}>
          <Text style={styles.body1}>
          {t('passphrase_intro')}
          </Text>
        </View>
        {/* <View style={styles.company}>
          <View>
            <Text style={styles.body1}>
              Street King William, 123
            </Text>
            <Text style={styles.body1}>
              Level 2, C, 442456
            </Text>
            <Text style={styles.body1}>
              San Francisco, CA, USA
            </Text>
          </View>
          <View>
            <Text style={styles.body1}>
              Company No. 4675933
            </Text>
            <Text style={styles.body1}>
              EU VAT No. 949 67545 45
            </Text>
          </View>
          <View>
            <Text style={styles.body1}>
              Email: accounts@devias.io
            </Text>
            <Text style={styles.body1}>
              Tel: (+40) 652 3456 23
            </Text>
          </View>
        </View> */}
        <View style={styles.references}>
          <View>
            <Text style={[styles.h5, styles.mb1]}>
              {t('passphrase_data_fullname')}
            </Text>
            <Text style={styles.body1}>
              {`${profile.last_name} ${profile.first_name}`}
            </Text>
          </View>
          <View>
            <Text style={[styles.h5, styles.mb1]}>
            {t('passphrase_data_dob')}
            </Text>
            <Text style={styles.body1}>
              {profile.dob}
            </Text>
          </View>
        </View>
        <View style={styles.items}>
          <Text style={[styles.h5, styles.mb1]}>
          {t('passphrase_data_notes')}
          </Text>
          <Text style={styles.body1}>
          {t('passphrase_info_1')}
          </Text>
          <Text style={[styles.body1]}>
          {t('passphrase_info_2')}
          </Text>
          <Text style={styles.body1}>
          {t('passphrase_info_3')}
          </Text>
        </View>
        <View style={styles.items}>
          <Text style={[styles.h5, styles.mb1]}>
          {t('passphrase_data_passphrase')}
          </Text>
          <View style={[styles.passphrase]}>
            {passphrase.split(' ').map((word, index) => (
              <Text key={index} style={styles.word}>{word}</Text>
            ))}
          </View>
        </View>
        {/* <View style={styles.items}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1}>
                  <Text style={styles.h6}>
                    Description
                  </Text>
                </View>
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN}>
                  <Text style={[styles.h6, styles.alignRight]}>
                    Price
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              {invoice.items.map((item) => (
                <View
                  style={styles.tableRow}
                  key={item.id}
                >
                  <View style={styles.tableCell1}>
                    <Text style={styles.body2}>
                      {item.description}
                    </Text>
                  </View>
                  <View style={styles.tableCellN} />
                  <View style={styles.tableCellN}>
                    <Text style={[styles.body2, styles.alignRight]}>
                      {numeral(item.unitAmount).format(`${item.currency}0,0.00`)}
                    </Text>
                  </View>
                </View>
              ))}
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>
                    Subtotal
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {numeral(invoice.subtotalAmount).format(`${invoice.currency}0,0.00`)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>
                    Taxes
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {numeral(invoice.taxAmount).format(`${invoice.currency}0,0.00`)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>
                    Total
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {numeral(invoice.totalAmount).format(`${invoice.currency}0,0.00`)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View> */}
        <View style={styles.items}>
          <Text style={[styles.h5, styles.mb1]}>
          </Text>
          <Text style={styles.body1}>
            {t('passphrase_wishbook_thanks')}
          </Text>
        </View>
      </Page>
    </Document>
  );
}

export default PassphrasePDF;
