import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import i18n from "src/i18n";
interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, hasValidatedEmail, isOnboarded } = useAuth();
  console.log(`[GuestGuard] isAuthenticated=${isAuthenticated}, hasValidatedEmail=${hasValidatedEmail}, isOnboarded=${isOnboarded}`);

  console.log(`Location: ${JSON.stringify(location)}`);

  // Login -> Need email validation TESTED OK.
  if (isAuthenticated && hasValidatedEmail == false) {
    console.log(`[GuestGuard] Go Email validation ${location.pathname}`);
    const routePath = `/${i18n.language}/email-validation`;
    if (location.pathname != routePath) { return <Redirect to={routePath} />; }
  }
  else if (isAuthenticated && hasValidatedEmail == true) {
    return <Redirect to={`/${i18n.language}`} />;
  }
  
  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
