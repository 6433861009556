import * as tus from 'tus-js-client';
import imageCompression from 'browser-image-compression';

import { base64ChunkSize, calculateBas64Size, calculateCipherSize, chunkSize, encryptFile } from "src/utils/crypto";
import { WFile, generateWFile } from "src/api/wishbook/safes/common/WFile";

import { TUSD_BASEURL } from "src/constants";
import { PersistentContext } from 'src/contexts/AuthContext/PersistentContext';

let defaultAuthorization = null;

export const assignDefaultAuthorizationBearerToken = (accessToken: string) => {
  if (accessToken) {
    defaultAuthorization = `Bearer ${accessToken}`;
  } else {
    defaultAuthorization = null;
  }
};

const determinePreciseCryptedLen = (fileSize: number) => {
  let totalLen = 0;
  let iter = Math.trunc(fileSize / chunkSize)

  for (let todo = iter; todo > 0; todo--) {
    totalLen += base64ChunkSize
  }
  const cipherLen = calculateCipherSize((fileSize - (iter * chunkSize)));
  const base64Len = calculateBas64Size(cipherLen);
  totalLen += base64Len
  return totalLen;
}

const createTusUploader = (context: PersistentContext, key: string, file: File, onSuccess = null, onError = null, isProfessional: boolean) => {
  const fileStream = ! isProfessional && context.isUsingEncryption() ? encryptFile(file, context.getCurrentSpace().keyring) : file.stream();
  const futureFileSize = ! isProfessional && context.isUsingEncryption() ? determinePreciseCryptedLen(file.size) : file.size;

  console.log(`Tus path on: ${key!}`);
  const upload = new tus.Upload(fileStream.getReader(), {
    endpoint: `${TUSD_BASEURL}/files/`,
    headers: {
      Authorization: defaultAuthorization,
    },
    chunkSize: ! isProfessional && context.isUsingEncryption() ? base64ChunkSize : chunkSize,
    uploadSize: futureFileSize,
    retryDelays: [0, 3000, 5000, 10000, 20000],
    addRequestId: true,
    metadata: {
      space: context.getCurrentSpace().type,
      user_id: context.getTargetUserId(),
      key: key,
      filename: file.name,
      filetype: file.type
    },
    onError: function(error) {
        console.log("Failed because: " + error);
        if (onError) { onError(error); }
    },
    onProgress: function(bytesUploaded, bytesTotal) {
        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        console.log(bytesUploaded, bytesTotal, percentage + "%")
    },
    onChunkComplete: (chunkSize: number, bytesAccepted: number, bytesTotal: number) => {
      console.log(`Chunk complete size: ${chunkSize}.`)
    },
    onSuccess: function() {
        console.log("Download %s from %s", file.name, upload.url);
        const splitUrl = upload.url.split('/');
        const uploadId = splitUrl[4];

        console.log("ID: %s", uploadId);
        // fileDocument.bucket = { tus_id: uploadId };

        console.log(upload);
        if (onSuccess) { onSuccess(uploadId); }
    },
  });
  // console.log(upload);
  return upload;
};

export interface FullUpload {
  success?: boolean,
  error?: Error,
  progress?: number,
  tusUpload?: tus.Upload,
}

export interface FileBundleUpload {
  file: File;
  fileDocument: WFile;
  key?: string;

  onFinish?: () => Promise<void>;
  rawUpload?: FullUpload;
  thumbnailUpload?: FullUpload;
};

export const buildFileBundle = (files: File[]): FileBundleUpload[] => {
  return files.map((file: File) => ({
      file,
      fileDocument: generateWFile(file),
    }));
};

export const createUploads = async (context: PersistentContext, fileBundles: FileBundleUpload[], shouldGenerateThumbnail: boolean, isProfessional: boolean): Promise<FileBundleUpload[]> => {
  return Promise.all(fileBundles.map(async (fileBundle) => {

    /*
    ** Raw file upload management
    */
    const rawUpload: FullUpload = { };
    rawUpload.tusUpload = createTusUploader(
      context,
      `${fileBundle.key}/raw_bucket`,
      fileBundle.file,
      async (uploadId) => {

        if (! uploadId) {
          return false;
        }

        fileBundle.fileDocument.raw_bucket = { tus_id: uploadId }
        rawUpload.success = true;
        if (fileBundle.thumbnailUpload && (fileBundle.thumbnailUpload.success || fileBundle.thumbnailUpload.error)) { await fileBundle.onFinish() }
        else { await fileBundle.onFinish() }
      },
      async (error) => {
        rawUpload.error = error;
        if (fileBundle.thumbnailUpload && (fileBundle.thumbnailUpload.success || fileBundle.thumbnailUpload.error)) { await fileBundle.onFinish() }
        else { await fileBundle.onFinish() }
      },
        isProfessional
    );

    /*
    ** Thumbnail management with resizing
    */
    let thumbnailUpload: FullUpload = null;
    if (shouldGenerateThumbnail) {
      const compressedFile = await imageCompression(fileBundle.file, {
        maxWidthOrHeight: 300 * 2,
      });

      thumbnailUpload = { };
      thumbnailUpload.tusUpload = createTusUploader(
        context,
        `${fileBundle.key}/thumbnail_bucket`,
        compressedFile,
        async (uploadId) => {
          fileBundle.fileDocument.thumbnail_bucket = { tus_id: uploadId }
          thumbnailUpload.success = true
          if (fileBundle.rawUpload.success || fileBundle.rawUpload.error) { await fileBundle.onFinish() }
        },
        async (error) => {
          thumbnailUpload.error = error;
          if (fileBundle.rawUpload.success || fileBundle.rawUpload.error) { await fileBundle.onFinish() }
        },
          isProfessional
      );
    }

    fileBundle.rawUpload = rawUpload;
    fileBundle.thumbnailUpload = thumbnailUpload;

    return fileBundle;
  }));
};

export const startUploads = (uploads: FileBundleUpload[], onFinish = null) => {
  let counter = 0;

  uploads.forEach((upload) => {
    upload.onFinish = async () => {
      counter += 1;
      if (counter === uploads.length) { await onFinish(); }
    }
  });

  uploads.forEach((upload) => {
    if (upload.rawUpload.tusUpload) {
      upload.rawUpload.tusUpload.start();
    }
    if (upload.thumbnailUpload && upload.thumbnailUpload.tusUpload) {
      upload.thumbnailUpload.tusUpload.start();
    }
  });
};
