import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {MemberType, SpaceStatus, UserType} from 'src/api/wishbook/users/model';
import i18n from "src/i18n";
import { SpaceType } from 'src/contexts/AuthContext/Space';
import {SubscriptionSkus} from "../../api/wishbook/subscriptions/helper";
import * as userApi from "../../api/wishbook/users/api";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, isOnboarded, hasValidatedEmail, context, makeNeedOnboarding } = useAuth();
  console.log(`[AuthGard] isAuthenticated=${isAuthenticated}, hasValidatedEmail=${hasValidatedEmail}, isOnboarded=${isOnboarded}`);
  const currentSpace = context.getCurrentSpace();

  const shouldSelectSpace = () => {
    // const currentSpace = context.getCurrentSpace();
    // console.log(`[AuthGard] SPACE TYPE: ${currentSpace.type}`);

    if (context.mySpace.wishbookers.length > 0 && ! context.hasSelectedSpace) return true;

    return false;
  };

  const shouldGetKeyring = () => {
    const currentSpace = context.getCurrentSpace();
    if (! context.isMe() && context.isMemberProfessional()) return false;
    if (currentSpace.type === SpaceType.CLASSIC && ! currentSpace.keyring && currentSpace.user.type !== 'MEMBER') return true;
    return false;
  };


  if (isAuthenticated == false) {
    console.log("Redirect to login here");
    return <Redirect to={`/${i18n.language}/login`} />;
  }

  // if (hasValidatedEmail == false) {
  //   console.log(`[AuthGard] Go Email validation ${location.pathname}`);
  //   const routePath = `/${i18n.language}/email-validation`;
  //   if (location.pathname != routePath) { return <Redirect to={routePath} />; }
  // }

  if (isAuthenticated /*&& hasValidatedEmail*/ && isOnboarded == false) {
    // if(isGuardian) return <Redirect to={`/${i18n.language}/space-select`} />;
    const onboardingRoutePath = `/${i18n.language}/onboarding`;
    const safeRoutePath = `/${i18n.language}/classic`;
    if (location.pathname != onboardingRoutePath && location.pathname != safeRoutePath) { return <Redirect to={onboardingRoutePath} />; }
  }

  if (isAuthenticated /*&& hasValidatedEmail*/ && isOnboarded) {

    if (shouldSelectSpace()) {
      if (location.pathname.includes("/onboarding") == false) {
        const routePathSpaceSelect = `/${i18n.language}/space-select`;
        if (location.pathname != routePathSpaceSelect) { return <Redirect to={routePathSpaceSelect} />; }
      }
    }

    else if (shouldGetKeyring()) {
      const routePathKeyring = `/${i18n.language}/keyring`;
      if (location.pathname != routePathKeyring) { return <Redirect to={routePathKeyring} />; }
    }

    console.log(`Here: ${location.pathname}`);

    // [Classic]
    // Members & directives are always accessible
    // if (location.pathname.includes("classic/") && location.pathname.includes("members") == false && location.pathname.includes("directives") == false) {
    //   const lockRoute = `/${i18n.language}/${context.getCurrentSpace().type}/lock`;
    //   if (context.getCurrentSpace().user.spaces.classic.status == SpaceStatus.CLOSED && location.pathname != lockRoute) {
    //     return <Redirect to={lockRoute} />;
    //   }
    // }

    if (location.pathname == `/${i18n.language}` || location.pathname == `/${i18n.language}/`) {
      const spaceType = context.getCurrentSpace().type;

      if(! spaceType) return <Redirect to={`/${i18n.language}/subscriptions/create/${SubscriptionSkus.comWishbookClassicFreemium}`} />;
      const routeInsideSpace = `/${i18n.language}/${spaceType}`;
      return <Redirect to={routeInsideSpace} />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
