import { v4 as uuidv4 } from 'uuid';

import { Meta } from 'src/api/wishbook/meta/model';
import { MetaType } from 'src/api/wishbook/meta/MetaConfiguration';
import { WAlbum } from "src/api/wishbook/safes/common/WAlbum"

export interface MetaAlbums extends Meta {
  content: {
    albums: {
      [prop: string]: WAlbum;
    }
  }
}

export const defaultAlbums = (metaType: MetaType): MetaAlbums => {
  return {
    content: {
      albums: { }
    },
    type: metaType,
  };
}

export const addAlbum = (metaAlbums: MetaAlbums, album: WAlbum) => {
  const key = uuidv4();
  metaAlbums.content.albums[key] = album;
};

export const deleteAlbum = (metaAlbums: MetaAlbums, albumKey: string) => {
  delete metaAlbums.content.albums[albumKey];
};

export const getItemReferenceKey = (metaAlbums: MetaAlbums, albumKey: string) => {
  const itemReferenceKey = `${metaAlbums.type}/${metaAlbums.id}/content.albums.${albumKey}`;
  return itemReferenceKey;
}